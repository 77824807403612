<template>
  <div
    class="content-container print"
    style="overflow: hidden"
    id="month_print"
  >
    <div v-for="item in dataList">
      <table style="width: 100%">
        <tr>
          <td>
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-size: 18px;
                font-weight: bold;
              "
            >
              Marvel Trucking Solutions Inc
            </div>
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-size: 9px;
              "
            >
              Mercedes Business Park, Mercedes Ave, Rizal
            </div>
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-size: 9px;
              "
            >
              BIR-TIN: 009-788-189-000
            </div>
          </td>
          <td></td>

          <td style="text-align: right">
            <img
              src="http://marveltrucking.com/wp-content/uploads/2019/03/marvellogo.png"
              style="height: 50px; width: 60%"
            />
          </td>
        </tr>
        <tr>
          <td style="text-align: center" colspan="4">
            <span
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-weight: bold;
              "
            >
              <span style="font-size: 20px">PAYSLIP &nbsp;</span>
            </span>
          </td>
        </tr>
        <tr>
          <td colspan="4" style="text-align: center">
            <span
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                font-weight: bold;
                font-style: italic;
              "
            >
              <span style="font-size: 15px"
                >Payslip for 13th month
                {{ $route.params.type == 1 ? "1st Half" : "2nd Half" }} Year :
                {{ $route.params.year }} &nbsp;</span
              >
            </span>
          </td>
        </tr>
      </table>

      <table style="width: 100%; font-size: 11px">
        <tr>
          <td style="padding-right: 7px">
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                padding-bottom: 7px;
              "
            >
              <span style="font-size: 13px">
                Name: <b>{{ item.personnel }}</b></span
              >
            </div>
          </td>
        </tr>
        <tr>
          <td style="padding-right: 7px">
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                padding-bottom: 7px;
              "
            >
              <span style="font-size: 13px">
                Position: <b>{{ "Driver" }}</b></span
              >
            </div>
          </td>
        </tr>

        <tr>
          <td
            style="
              padding-right: 7px;
              vertical-align: top;
              font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
            "
            colspan="3"
          >
            <table
              style="
                border: 1px solid black;
                width: 100%;
                font-size: 11px;
                margin-top: 0px;
              "
            >
              <tr style="border: 1px solid black">
                <td
                  style="
                    width: 10%;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                    text-align: left;
                    font-weight: bold;
                  "
                >
                  Earnings
                </td>
                <td
                  style="
                    width: 10%;
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                    text-align: left;
                    font-weight: bold;
                  "
                >
                  Amount
                </td>
              </tr>
              <tr
                style="border: 1px solid black; height: 20px"
                v-for="index in 1"
                :key="index"
              >
                <td
                  style="
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  <strong>13th month </strong>
                </td>
                <td
                  style="
                    border-bottom: 1px solid black;
                    border-right: 1px solid black;
                  "
                >
                  <strong>{{ item["13th_month_total"] }}</strong>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>

      <table
        style="
          width: 100%;
          font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
          font-size: 11px;
        "
      >
        <tr>
          <td style="vertical-align: text-top; padding-left: 7px">
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                padding-bottom: 7px;
                font-weight: bold;
                font-style: italic;
              "
            >
              Total Earnings
            </div>
          </td>
          <td style="vertical-align: text-top">
            <div
              style="font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding-bottom: 7px font-weight: bold; font-style: italic;"
            >
              <span
                v-if="!item['13th_month_total'] == '0'"
                style="margin-right: 40px"
              >
                <b>{{ item["13th_month_total"] + ".00" }}</b></span
              >
              <span v-else style="margin-right: 60px">
                <b>{{ item["13th_month_total"] + ".00" }}</b></span
              >
            </div>
          </td>
        </tr>
      </table>
      <br />
      <table
        style="
          width: 100%;
          font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
          font-size: 11px;
        "
      >
        <tr>
          <td style="vertical-align: text-top; padding-left: 7px">
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                margin-bottom: -5px;
                padding-left: 20px;
              "
            >
            <strong>{{ item.personnel }}</strong>
            </div>
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                margin-bottom: -10px;
              "
            >
              ________________________
            </div>
            <br />
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                padding-bottom: 7px;
              "
            >
              Employee's Signature
            </div>
          </td>
          <td style="vertical-align: text-top; padding-left: 7px">
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                margin-bottom: -10px;
              "
            >
              ________________________
            </div>
            <br />
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                padding-bottom: 7px;
              "
            >
              Prepared By
            </div>
          </td>
        </tr>

        <tr>
          <td style="vertical-align: text-top; padding-left: 7px">
            <div
              style="
                font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
                padding-bottom: 10px;
              "
            >
              Date:______________________
            </div>
          </td>
        </tr>
      </table>    
      ---------------------------------------------------------------------------------------------------------------------------------------  
    </div>
  </div>
</template>

<style scoped>
@page {
  size: auto;
  margin: 0mm;
}
.test {
  color: blue;
}
@media print {
  .col-special-1,
  .col-special-2,
  .col-special-3,
  .col-special-4,
  .col-special-5,
  .col-special-6,
  .col-special-7,
  .col-special-8,
  .col-special-9,
  .col-special-10,
  .col-special-11,
  .col-special-12 {
    float: left;
  }

  .col-special-12 {
    width: 100%;
  }

  .col-special-11 {
    width: 91.66666666666666%;
  }

  .col-special-10 {
    width: 83.33333333333334%;
  }

  .col-special-9 {
    width: 75%;
  }

  .col-special-8 {
    width: 66.66666666666666%;
  }

  .col-special-7 {
    width: 58.333333333333336%;
  }

  .col-special-6 {
    width: 50%;
  }

  .col-special-5 {
    width: 41.66666666666667%;
  }

  .col-special-4 {
    width: 33.33333333333333%;
  }

  .col-special-3 {
    width: 25%;
  }

  .col-special-2 {
    width: 16.666666666666664%;
  }

  .col-special-1 {
    width: 8.333333333333332%;
  }
}
</style>

<script>
import moment from "moment";
export default {
  mounted() {},
  props: {
    details: {
      booking_no: "",
      equipment: "",
      vehicle_type_id: "",
      maker_model_id: "",
      origin_id: "",
      vendor_id: "",
      driver_id: "",
      body_no: "",
      year: "",
      with_gps: "",
      company_owned: true,
      plate_no: "",
      registration_date: "",
      asset_no: "",
      engine_no: "",
      engine_size: "",
      chassis_no: "",
      transmission_type: "",
      transfer_date: "",
      color: "",
      fuel_type: "",
      later_renewal_date: "",
      acquisition_date: "",
      acquisition_price: "",
      remarks: "",
      vehicle_photo: "",
      or_photo: "",
      cr_photo: "",
      insurance_photo: "",
      status: "active",
      location: "",
    },
    dataList: [],
  },
  data() {
    return {
      moment,
    };
  },
};
</script>
