<template>
	<div>
		<CCard class="p-2"> 
			<CCardHeader>
				<CRow style="color: #1352a1;">
			
					<!-- <CCol lg="6">
						
					</CCol>  -->
					<CCol lg="12">
			      		<div style="text-align: right">  
							<CButton v-if="config.getPermission('13th_month').download" class="mr-1" color="btn btn-outline-primary" size="sm" @click="downloadBookingsSummary"><i class="fa fa-download"></i> Download Bookings Summary</CButton>
							<CButton v-if="config.getPermission('13th_month').download" class="mr-1" color="btn btn-outline-primary" size="sm" @click="download"><i class="fa fa-download"></i> Download 13th Month</CButton>
							<CButton v-if="config.getPermission('13th_month').print" class="mr-1" color="btn btn-info" size="sm" @click="print"><i class="fa fa-print"></i> Print</CButton> 
			      			<CButton class="mr-1" color="btn btn-danger" size="sm" @click="backToTable()"><i class="fa fa-close"></i> Close</CButton>
			      		</div>
					</CCol>
				</CRow>
			</CCardHeader>
	<CCardBody>
				
					<CRow class="ml-2 mr-2 mt-1">
						<CCol lg="6">
							<h4>Marvel Trucking Solutions Inc.</h4>
				
						</CCol> 
							<CCol lg="6">
								<img class="float-right" src="http://marveltrucking.com/wp-content/uploads/2019/03/marvellogo.png" style="height: 55px;"/>
						</CCol>	
					</CRow>
					<CRow class="ml-2 mr-2">
						<CCol lg="6" style="margin-top:-28px;">
							<h6>13th Month Summary Report for {{$route.params.type == 1 ? '1st Half' : '2nd Half'}} Year : {{ $route.params.year }}</h6>
				
						</CCol> 
							
					</CRow>
	
					<CRow> 
						<CCol lg="12" style="margin-top: 30px;">
							<CDataTable
							
								:tableFilter='{ placeholder: "Search Name" }'
								pagination
						      	:items="displayedBooking"
						      	:fields="$route.params.type == 1 ? first_half_fields : second_half_fields"
								hover
								size="sm"
								class="table-borderless"
								:itemsPerPage="20"
								itemsPerPageSelect
								
								
							
						    >  
						    </CDataTable> 
						    <!-- <CPagination
					          	:active-page.sync="currentPage"
					          	:pages="Math.ceil(displayedBookingHolder.length / limit)"
								  @update:activePage="updatePage"
					        /> -->
						</CCol>  
						
					</CRow> 
				
	</CCardBody> 
			  
</CCard>  

<Print :details="dataParams" :dataList="dataList"/>
		
		
	</div>
</template>
<script> 

import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import Loader from '../../../loader.mixin.js';
import '../../style.css';
import moment from 'moment';
import Print from './print';
import { json } from 'body-parser';


export default {
	mounted(){  
		this.getData(); 				
	},
	data(){
		return{
			displayedBooking: [],
			currentPage: 1,
			
			second_half_fields:  [
				{
					key: 'personnel', 
					label: 'Employee Name'
				},
				
				{
					key: '1st_month', 
					label: 'June'
				}, 
				{
					key: '2nd_month', 
					label: 'July'
				}, 
				
				{
					key: '3rd_month', 
					label: 'August'
				},
				{
					key: '4th_month', 
					label: 'September'
				}, 
				{
					key: '5th_month', 
					label: 'October'
				}, 
				{
					key: '6th_month', 
					label: 'November'
				}, 
				
				{
					key: '13th_month_total', 
					label: '13th Month'
				}, 
				
			],    
			first_half_fields:
			  [
				{
					key: 'personnel', 
					label: 'Employee Name'
				},
				
				{
					key: '1st_month', 
					label: 'December'
				}, 
				{
					key: '2nd_month', 
					label: 'January'
				}, 
				
				{
					key: '3rd_month', 
					label: 'February'
				},
				{
					key: '4th_month', 
					label: 'March'
				}, 
				{
					key: '5th_month', 
					label: 'April'
				}, 
				{
					key: '6th_month', 
					label: 'May'
				}, 
				
				{
					key: '13th_month_total', 
					label: '13th Month'
				}, 
				
			],    

			dataParams: {
			
				remarks: "",				
			},			
			dataList: [ ],
			isLoading: false,
			config,
			moment,
			limit: 20
		}
	},
	name: 'Tables',
	components: { Datepicker, vSelect,Print},
	methods: {  
		// updatePage(page) {
		// 	let t = [...this.displayedBookingHolder];
		// 	let index = (page - 1) * this.limit;
		// 	this.displayedBooking = t.slice(index, index + this.limit);
		// },
		reload(){
			setTimeout(()=>{
				this.getData();
			},500) 
		},
		getData(){
			this.$showLoading(true)

			axios.get(config.api_path+'/thirteenth-month/detail',{
			params: {id : this.$route.params.id}
		}).then(res => {
			const {data, status} = res.data;
			this.dataList = data;			
			
			this.displayedBooking = data.map(item => {
				item['1st_month'] = this.$options.filters.number_format(item['1st_month'], null, 2);
				item['2nd_month'] = this.$options.filters.number_format(item['2nd_month'], null, 2);
				item['3rd_month'] = this.$options.filters.number_format(item['3rd_month'], null, 2);
				item['4th_month'] = this.$options.filters.number_format(item['4th_month'], null, 2);
				item['5th_month'] = this.$options.filters.number_format(item['5th_month'], null, 2);
				item['6th_month'] = this.$options.filters.number_format(item['6th_month'], null, 2);
				item['13th_month_total'] = this.$options.filters.number_format(item['13th_month_total'], 'currency', 2);
				return item;
			});

			// this.displayedBookingHolder = [...this.displayedBooking];

			// let t = [...this.displayedBooking];
			// let index = 0;
			// this.displayedBooking = t.slice(index, this.limit);


			this.$showLoading(false);
			
		})
		.catch(err => {
			this.$showLoading(false)
			
		})
	},
	
		
	    backToTable(){
	    	this.$router.push('/main/13_month')
	    }, 
		download() {
			this.$showLoading(true)
			axios.get(config.api_path+'/thirteenth-month/report',{
				params: {id : this.$route.params.id}
			}).then(response => {
				this.$showLoading(false)
				window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 

					setTimeout(() => {
						axios.get(`${config.api_path}/remove-file`,{
							params : {
								filename : response.data.file
							}
						})	
					}, 2000);
				
			}).catch(err => {
				this.$showLoading(false)
			})
		},
		downloadBookingsSummary() {
			this.$showLoading(true)
			axios.get(config.api_path+'/thirteenth-month/reportBookingsSummary',{
				params: {id : this.$route.params.id}
			}).then(response => {
				this.$showLoading(false)
				window.open(config.main_path.replace('/index.php', '')+'/'+response.data.file) 

					setTimeout(() => {
						axios.get(`${config.api_path}/remove-file`,{
							params : {
								filename : response.data.file
							}
						})	
					}, 2000);
				
			}).catch(err => {
				this.$showLoading(false)
			})
		},
		
		 print(){

	    	var mywindow = window.open('', 'PRINT');

		    mywindow.document.write('<html><head>');
		    mywindow.document.write('<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css" integrity="sha384-KyZXEAg3QhqLMpG8r+8fhAXLRk2vvoC2f3B09zVXn8CA5QIVfZOJ3BCsw2P0p/We" crossorigin="anonymous">')
		    mywindow.document.write('<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">')
		    mywindow.document.write('</head><body >'); 
		    mywindow.document.write(document.getElementById('month_print').innerHTML);
		    mywindow.document.write('</body></html>');

		    mywindow.document.close(); // necessary for IE >= 10
		    mywindow.focus(); // necessary for IE >= 10*/

		    mywindow.print();
		    mywindow.close();
	    },

	   

	   
		
 	}
	
}
</script>
